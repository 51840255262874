export const routes = [
    {
        path: '/login', // 登录页
        name: 'login',
        component: () => import('@/components/login/Login.vue'),
    },
    {
        path: '/bindTel', // 绑定手机
        name: 'bindTel',
        component: () => import('@/components/bindTel/BindTel.vue'),
    },
    {
        path: '/', // 首页
        name: 'index',
        component: () => import('@/components/index/Index.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/index/search', // 首页-搜索
        name: 'index-search',
        component: () => import('@/components/index/Search.vue'),
    },
    {
        path: '/index/rankAndUpdate', // 首页-搜索-综合排序/最近更新
        name: 'index-rankAndUpdate',
        component: () => import('@/components/index/RankAndUpdate.vue'),
    },
    {
        path: '/index/guanZhu', // 首页-关注公众号
        name: 'index-guanZhu',
        component: () => import('@/components/index/GuanZhu.vue'),
    },
    {
        path: '/index/everyClass', // 首页-关注公众号
        name: 'index-everyClass',
        component: () => import('@/components/index/EveryClass.vue'),
    },
    {
        path: '/course', // 课程
        name: 'course',
        component: () => import('@/components/course/Course.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/course/courseDetail', // 课程-课程详情
        name: 'course-courseDetail',
        component: () => import('@/components/course/CourseDetail.vue'),
    },
    {
        path: '/my', // 我的
        name: 'my',
        component: () => import('@/components/my/My.vue'),
    },
    {
        path: '/my/collection', // 我的-收藏
        name: 'my-collection',
        component: () => import('@/components/my/Collection.vue'),
        meta: {
            keepAlive: true,
        },
    },
    {
        path: '/my/history', // 我的-历史
        name: 'my-history',
        component: () => import('@/components/my/History.vue'),
    },
    {
        path: '/my/feedback', // 我的-反馈
        name: 'my-feedback',
        component: () => import('@/components/my/HelpAndFeedback.vue'),
    },
    {
        path: '/my/setting', // 我的-设置
        name: 'my-setting',
        component: () => import('@/components/my/Setting.vue'),
    },
    {
        path: '/my/userInfo', // 我的-设置-个人信息
        name: 'my-userInfo',
        component: () => import('@/components/my/setUp/UserInfo.vue'),
    },
    {
        path: '/my/bindPhone', // 我的-设置-绑定账号
        name: 'my-bindPhone',
        component: () => import('@/components/my/setUp/BindPhone.vue'),
    },
    {
        path: '/my/accountExamine', // 我的-设置-更换账号
        name: 'my-accountExamine',
        component: () => import('@/components/my/setUp/AccountExamine.vue'),
    },
    {
        path: '/my/userCancellation', // 我的-设置-账号注销
        name: 'my-userCancellation',
        component: () => import('@/components/my/setUp/UserCancellation.vue'),
    },
    {
        path: '/my/selectLogOutReason', // 我的-设置-账号注销-选择注销原因
        name: 'my-selectLogOutReason',
        component: () => import('@/components/my/setUp/SelectLogOutReason.vue'),
    },
    {
        path: '/my/setting/address', // 我的-设置-收货地址
        name: 'my-setting-address',
        component: () => import('@/components/my/Address.vue'),
    },
    {
        path: '/my/setting/edit-address', // 我的-设置-编辑收货地址
        name: 'my-setting-edit-address',
        component: () => import('@/components/my/EditAddress.vue'),
    },
    {
        path: '/my/aboutOur', // 关于我们
        name: 'my-aboutOur',
        component: () => import('@/components/my/AboutOur.vue'),
    },
    {
        path: '/my/aboutOur/agreementAndPrivacy', // 关于我们
        name: 'my-aboutOur-agreementAndPrivacy',
        component: () => import('@/components/my/AgreementAndPrivacy.vue'),
    },
    {
        path: '/my/aboutOur/protectChildren', // 关于我们
        name: 'my-aboutOur-protectChildren',
        component: () => import('@/components/my/ProtectChildren.vue'),
    },
    {
        path: '/my/study/study', // 学习中心
        name: 'my-study',
        component: () => import('@/components/my/study/Study.vue'),
    },
    {
        path: '/my/study/ranking', // 学习排行
        name: 'my-ranking',
        component: () => import('@/components/my/study/Ranking.vue'),
    },
]
