import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogin: false,
        setting: {
            userInfoType: '',
        },
        myInfo: {},
        // 绑定账号：用户是否是创客账户
        isCKAccount: null,
        // 我的收藏：记录当前滚动位置
        myCollectPosition: 0,
        // 是否为小程序环境
        isMiniProgram: false,
        // 从儿童保护声明 => 隐私政策
        childrenGoPrivacy: false,
        // 课程数据
        courseData: {},
        // 搜索内容
        searchCache: '',
        gender: '',
    },
    getters: {
        getMyInfo: state => {
            return state.myInfo
        },
        getGender: state => {
            return state.gender
        }
    },
    mutations: {
        SET_USER_LOGIN_STATE(state, value) {
            state.isLogin = value
        },
        setYuTel(state,val){
            state.myInfo.baseInfo.yuTel = val
        },
        setCKAccount(state, value) {
            state.isCKAccount = value
        },
        // 区分个人信息中模块
        setDivideModule(state, value) {
            state.setting.userInfoType = value
        },
        setMyInfo(state, value) {
            state.myInfo = value
        },
        setLearningRecord(state, value) {
            state.myInfo.learningRecord = value
        },
        setLearningReport(state, value) {
            state.myInfo.learningReport = value
        },
        setBasicInfo(state, value) {
            state.myInfo.basicInfo = value
        },
        setAccumulateData(state, value) {
            state.myInfo.accumulateData = value
        },
        setMyCollectPosition(state, value) {
            state.myCollectPosition = value
        },
        setMiniProgram(state, value) {
            state.isMiniProgram = value
        },
        saveCourseData(state, newData) {
            state.courseData = newData;  // 更新 课程数据
        },
        setChildrenGoPrivacy(state, value) {
            state.childrenGoPrivacy = value
        },
        setSearchCache(state, value) {
            state.searchCache = value; 
        },
        setGender(state,value) {
            state.gender = value
        }
    },
    actions: {
        // 判断用户是否登录
        setUserLoginState({ commit }, value) {
            commit('SET_USER_LOGIN_STATE', value)
        },
        updateCourseData(context, newData) {
            context.commit('saveCourseData', newData); 
        },
        updateSearchCache({ commit }, value) {
            commit('setSearchCache', value); 
        },
        updateGender({ commit }, value) {
            commit('setGender', value); 
        },
    },
    modules: {},
})
