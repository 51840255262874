<template>
    <div id="app">
<!--        <div style="position: fixed;z-index: 999;left: 20px;top: 80px;font-size: 12px" @click="reload">relod</div>-->
        <!-- 顶部固定header -->
        <TopHolder />
        <keep-alive>
            <router-view class="view" v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view class="view" v-if="!$route.meta.keepAlive"></router-view>
        <BottomNavTabs v-if="showBottomNav" />
    </div>
</template>
<script>
import TopHolder from "@/public/components/TopHolder";
import BottomNavTabs from '@/public/components/tabs/BottomNavTabs';
/**
 * 展示底部导航的路径
 */
const showBottomNavPaths = ['/my', '/course', '/'];
export default {
    name: "App",
    components: {
        BottomNavTabs,
        TopHolder,
    },
    computed: {
        /**
         * 底部导航是否显示
         */
        showBottomNav() {
            return showBottomNavPaths.includes(this.$route.path);
        }
    },
    methods:{
        reload() {
            location.reload();
        }
    },
    mounted() {
        window.onload = function() {
            // 发送消息到小程序
            window.parent.postMessage({ message: 'Hello from webview!' }, '*');
        };
        // 判断是否是微信小程序环境
        let isMini = window.__wxjs_environment === 'miniprogram' || /miniProgram/i.test(window.navigator.userAgent) 
        sessionStorage.setItem('isMiniProgram', isMini)
        this.$store.commit('setMiniProgram', sessionStorage.getItem('isMiniProgram'))
        window.addEventListener('popstate', () => {
            console.log("回退===》》》》。")
            console.log(this.$route.path)
            const path = this.$route.path
            // setTimeout(()=>{
                if(path === "/login"){
                    window.location.href =  window.baseUrl
                }
            // },100)
            // 处理回退键事件
        });
    },
}
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    list-style: none;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-align: center;
    color: #2c3e50;
    background-image: url(@/assets/img/aboutOur/Bg.png);
    background-attachment: fixed;
    min-height: 100vh;

    .view {
        // padding-bottom: 50px;
    }
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
