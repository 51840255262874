
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import iView from 'iview'
import { Request } from './public/js/request'
import { MessageHelper } from './public/js/message-helper'
import 'iview/dist/styles/iview.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'amfe-flexible'
import './commoncss/scrollbar.less'
// import vs from "vconsole"
// new vs()
import Bus from "./bus/bus";
//实体字符集正常显示
String.prototype.EntityCharacterToText = function () {
    const div = document.createElement('div')
    // console.log(this);
    div.innerHTML = this
    const text = div.innerText

    return text
}
String.prototype.replacepos = function (start, stop, replacetext) {
    const mystr = this.substring(0, start) + replacetext + this.substring(stop + 1)
    return mystr
}

// Swipe 轮播组件
import { Swipe, SwipeItem } from 'vant'
// 列表组件
import { List } from 'vant'
// Popup 弹出层
import { Popup } from 'vant'
//
import { Overlay } from 'vant';
// 时间选择器
import { DatetimePicker } from 'vant'
// field 输入框
import { Field } from 'vant'
import { Loading } from 'vant'

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Field)
Vue.use(Loading)
Vue.use(Overlay);
Vue.prototype.$bus = Bus;

Vue.request = Vue.prototype.$request = Request
Vue.messageHelper = Vue.prototype.$messageHelper = MessageHelper
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(iView)
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
