import {
    Message
} from 'element-ui';

const MessageConfig = {
    duration: 3000
}

class MessageHelper {
    static messageInfo(info) {
        Message.closeAll();
        let config = Object.assign({}, MessageConfig, {
            message: info,
        });
        Message.info(config);
    }

    static messageSuccess(info) {
        Message.closeAll();
        let config = Object.assign({}, MessageConfig, {
            message: info,
        });
        Message.success(config);
    }

    static messageWarning(info) {
        Message.closeAll();
        let config = Object.assign({}, MessageConfig, {
            message: info,
        });
        Message.warning(config);
    }

    static messageError(info) {
        Message.closeAll();
        let config = Object.assign({}, MessageConfig, {
            message: info,
        });
        Message.error(config);
    }
}

export {
    MessageHelper
}