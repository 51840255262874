<template>
    <div class="topHolder">
        <template v-if="$route.query.moduleTitle || $route.path === '/'">
            <img v-if=" $route.path !== '' && $route.query.moduleTitle && $route.query.moduleTitle !== '首页' && $route.query.moduleTitle !== '乐韵学堂' && $route.query.moduleTitle !== '' && $route.query.moduleTitle !== '课程'&& $route.query.moduleTitle !== '我的'|| $route.query.itemTypeObj" @click="back" src="../../assets/img/aboutOur/leftArrow_icon.png" alt="" />
            <span v-if="$route.path != '/index/search'" class="oneLines-ellipsis">{{ $route.path === '/' ? '乐韵学堂' :
                $route.query.moduleTitle?.EntityCharacterToText()}}</span>
        </template>
    </div>
</template>

<script>
export default {
    name: "TopHolder",
    computed: {
        moduleTitle() {
            // let moduleTitle = this.$route.query.moduleTitle !== '首页' && this.$route.query.moduleTitle !== '课程' && this.$route.query.moduleTitle !== '我的' || this.$route.query.itemTypeObj;
            // this.title
            let moduleTitle = this.$route.query.moduleTitle && this.$route.query.moduleTitle !== '首页' && this.$route.query.moduleTitle !== '乐韵学堂' && this.$route.query.moduleTitle !== '' && this.$route.query.moduleTitle !== '课程'&& this.$route.query.moduleTitle !== '我的'|| this.$route.query.itemTypeObj;
            return moduleTitle;
        }
    },
    data(){
        return {
            // title: this.$route.query.moduleTitle,
            path: this.$route.path
        }
    },
    watch: {
        "$route.query.moduleTitle"(val){
            // this.title = val.path
        }
    },
    methods: {
        back() {
            if (window.showGuanWang) {
                window.showGuanWang();
                window.showGuanWang = null;

                return;
            }
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="less" scoped>
.topHolder {
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: 500;
    font-size: 16px;
    color: #1c1c1c;
    letter-spacing: 1px;
    font-style: normal;
    display: flex;
    align-items: center;
    margin-left: 18px;

    >img {
        width: 15px;
        height: 15px;
        transform: rotate(180deg);
    }

    >span {
        display: inline-block;
        width: 173px;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);

    }
}
</style>