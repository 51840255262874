<template>
    <div class="BottomNavTabs">
        <div v-for="item in BottomNavTabs" class="navItem" :key="item.path" @click="debounceClickFn(item)"
            :class="{ active: $route.path === item.path }">
            <div class="icon">
                <img :src="$route.path === item.path ? item.activeIcon : item.icon" alt="" />
            </div>
            <div class="navName">{{ item.label }}</div>
        </div>
    </div>
</template>
<script>
import { BottomNavTabs } from "./Tabs.js";
import { debounce } from 'lodash';
export default {
    name: "BottomNavTabs",
    components: {},
    mixins: [],
    props: {},
    data() {
        return {
            BottomNavTabs,
            debounceClickFn: () => {},
        };
    },
    computed: {},
    watch: {},
    created() {
        this.debounceClickFn = debounce(this.clickFn, 500)
    },
    mounted() { },
    methods: {
        clickFn(item) {
            if (this.$route.path === item.path) return;
            this.$router.push({
                path: item.path,
                query: {
                    moduleTitle: item.label,
                },
            });
        },
    },
};
</script>
<style lang='less' scoped>
.BottomNavTabs {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 65px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    >.navItem {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        >.icon {
            width: 25px;
            height: 25px;
            margin-bottom: 3px;
            display: flex;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        >.navName {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 10px;
            color: #b7b8b9;
            font-style: normal;
        }

        &.active {
            >.navName {
                color: #aa1e28;
            }
        }
    }
}
</style>