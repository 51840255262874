var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "topHolder" },
    [
      _vm.$route.query.moduleTitle || _vm.$route.path === "/"
        ? [
            (_vm.$route.path !== "" &&
              _vm.$route.query.moduleTitle &&
              _vm.$route.query.moduleTitle !== "首页" &&
              _vm.$route.query.moduleTitle !== "乐韵学堂" &&
              _vm.$route.query.moduleTitle !== "" &&
              _vm.$route.query.moduleTitle !== "课程" &&
              _vm.$route.query.moduleTitle !== "我的") ||
            _vm.$route.query.itemTypeObj
              ? _c("img", {
                  attrs: {
                    src: require("../../assets/img/aboutOur/leftArrow_icon.png"),
                    alt: "",
                  },
                  on: { click: _vm.back },
                })
              : _vm._e(),
            _vm.$route.path != "/index/search"
              ? _c("span", { staticClass: "oneLines-ellipsis" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$route.path === "/"
                        ? "乐韵学堂"
                        : _vm.$route.query.moduleTitle?.EntityCharacterToText()
                    )
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }