import axios from 'axios';
import qs from 'qs';

import {
    MessageHelper
} from './message-helper';
// fixme 修改指示器组件
import {
    getBaseUrl
} from './base-url';

const WhiteLists = [
    "/ypv1/home/getRecommend", "/ypv1/home/getHotCourse", "/ypv1/home/getLatestUpload", "/ypv1/home/getMusicClass", "/ypv1/search/getCourseList", "/ypv1/settings/submitFeedback",
];
// if(!sessionStorage.getItem("axiosBaseurl")){
    //这里防止页面刷新参数不对
    axios.defaults.baseURL = getBaseUrl();
    // sessionStorage.setItem("axiosBaseurl",axios.defaults.baseURL)
// }
axios.defaults.timeout = 20000;
axios.defaults.withCredentials = true;
axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    if (error.message.includes('timeout') || error.code === 'ECONNABORTED') {
        MessageHelper.messageWarning("请求超时")
    }
    // 对响应错误做处理
    return Promise.reject(error);
});

class Request {
    static get(parameter) {
        parameter.type = 'get';
        this.fixParameterData(parameter);
        if (parameter.data) {
            parameter.url += `?${parameter.data}`;
            delete parameter.data;
        }
        return this.request(parameter);
    }

    static post(parameter) {
        parameter.type = 'post';
        this.fixParameterData(parameter);
        return this.request(parameter);
    }

    static request(parameter) {
        if (localStorage.getItem("Authorization")) {
            axios.defaults.headers.Authorization = localStorage.getItem("Authorization")
        }
        return new Promise((resolve, reject) => {
            if (!window.navigator.onLine) {
                // 网络离线时提示不自动消失
                return MessageHelper.messageWarning('网络异常，请检查网络连接');
            }
            axios[parameter.type](parameter.url, parameter.data).then(function (response) {
                // 只有包含yinyueyuandi的接口才更新token信息
                // console.log(response.config.url.includes('yinyueyuandi'),response.config.url,546546);
                if (response.config.url.includes('yinyueyuandi')) {
                    localStorage.setItem("Authorization", response.headers.authorization);
                }
                if (parameter.url.endsWith("/logout")) {
                    if (response.data.success) {
                        localStorage.removeItem("Authorization")
                    }
                }
                resolve(response.data || {
                    status: -100,
                    msg: 'response.data is null'
                });
            }).catch((err) => {
                if (err.response && !this.inWhiteList(err.response.config.url)) {
                    if (err.response.status === 401) {
                        // MessageHelper.messageError(`未授权，请重新登录`);
                        console.log('未授权，请重新登录');
                    } else if (err.response.status === 403) {
                        MessageHelper.messageError(`服务器请求失败，稍后重试`);
                    } else if (err.response.status === 404) {
                        MessageHelper.messageError(`服务器找不到当前请求`);
                    } else if (err.response.status === 500) {
                        MessageHelper.messageError(`服务器异常`);
                    } else if (err.response.status === 408) {
                        MessageHelper.messageError(`请求超时`);
                    } else if (err.response.status !== 200) {
                        MessageHelper.messageError(`接口异常`);
                    }
                } else if (err.request) {
                    console.log(err.request);
                } else {
                    console.log('Error', err.message);
                }
                reject(err);
            });
        });
    }

    static fixParameterData(parameter) {
        (parameter.data && parameter.data.constructor.name === Object.name) && (parameter.data = qs.stringify(parameter.data));
    }

    /**
     * 判断给定的URL是否在白名单中
     *
     * @param url 要判断的URL
     * @returns 如果URL在白名单中，则返回true；否则返回false
     */
    static inWhiteList(url) {
        return WhiteLists.some(list => url.includes(list));
    }
}

export {
    Request
}