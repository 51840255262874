const InstrumentTypeMap = {
    All: 0,
    ShuDi: 1,
    HuLuSi: 2,
    KouQin: 3
}

const InstrumentMap = [{
        name: '全部',
        type: InstrumentTypeMap.All
    },
    {
        name: '竖笛',
        type: InstrumentTypeMap.ShuDi
    },
    {
        name: '葫芦丝',
        type: InstrumentTypeMap.HuLuSi
    },
    {
        name: '口琴',
        type: InstrumentTypeMap.KouQin
    },
]

const BottomNavTabs = [{
        label: '首页',
        icon: require("@/assets/img/bottomNavTabs/home.png"),
        activeIcon: require("@/assets/img/bottomNavTabs/home_active.png"),
        path: '/'
    },
    {
        label: '课程',
        icon: require("@/assets/img/bottomNavTabs/course.png"),
        activeIcon: require("@/assets/img/bottomNavTabs/course_active.png"),
        path: '/course'
    },
    {
        label: '我的',
        icon: require("@/assets/img/bottomNavTabs/my.png"),
        activeIcon: require("@/assets/img/bottomNavTabs/my_active.png"),
        path: '/my'
    }
]

export {
    InstrumentTypeMap,
    InstrumentMap,
    BottomNavTabs
}