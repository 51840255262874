// 不同环境根地址
export function getBaseUrl() {

    // 获取当前页面的 URL
    const currentUrl = window.location.href;
    // 创建 URL 对象
    const url = new URL(currentUrl);
    // console.log(process.env)
    // if (process.env.url === 'devUrl') {
    //     // 生产环境的逻辑
    //     url.searchParams.set('baseUrl',"https://dev-yqt.yinyueyuandi.com/")
    // } else if (process.env.url === 'preUrl') {
    //     url.searchParams.set('baseUrl',"https://dev-yqt.yinyueyuandi.com/")
    //     // 开发环境的逻辑
    // } else {
    //     url.searchParams.set('baseUrl',"https://yqt.yinyueyuandi.com/")
    //     // 测试环境的逻辑
    // }
    console.log(url.searchParams.get('nickName'))


    // 使用 URLSearchParams 获取参数
    const baseUrl = url.searchParams.get('baseUrl') || url.origin; // 获取 baseUrl 参数//前面的方便调试开发
    window.baseUrl = baseUrl;
    window.nickName = url.searchParams.get('nickName');
    return baseUrl;
}