var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BottomNavTabs" },
    _vm._l(_vm.BottomNavTabs, function (item) {
      return _c(
        "div",
        {
          key: item.path,
          staticClass: "navItem",
          class: { active: _vm.$route.path === item.path },
          on: {
            click: function ($event) {
              return _vm.debounceClickFn(item)
            },
          },
        },
        [
          _c("div", { staticClass: "icon" }, [
            _c("img", {
              attrs: {
                src:
                  _vm.$route.path === item.path ? item.activeIcon : item.icon,
                alt: "",
              },
            }),
          ]),
          _c("div", { staticClass: "navName" }, [_vm._v(_vm._s(item.label))]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }